@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: ColgateBold;
  src: url('./fonts/COLGATEREADY-BOLD.woff');
}

@font-face {
  font-family: ColgateLight;
  src: url('./fonts/COLGATEREADY-LIGHT.woff');
}

@font-face {
  font-family: Kids;
  src: url('./fonts/Letter-Kids-Demo.woff');
}

@font-face {
  font-family: MyriadProReg;
  src: url('./fonts/MyriadPro-Regular.woff');
}

@font-face {
  font-family: CenturyGothic;
  src: url('./fonts/GOTHIC.woff');
}

@font-face {
  font-family: CenturyGothic-Bold;
  src: url('./fonts/GOTHICB.woff');
}

@font-face {
  font-family: CenturyGothic-Bold-Italic;
  src: url('./fonts/GOTHICBI.woff');
}

@font-face {
  font-family: CenturyGothic-Italic;
  src: url('./fonts/GOTHICI.woff');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #FFF0;
}
*::-webkit-scrollbar-thumb {
  background: #FFF;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #8a1913;
}

.swal2-popup {
  border-radius: 40px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}
